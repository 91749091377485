<template>
  <section id="sale-report-detail">
    <b-overlay
      :show="loading || !saleReportToUpdate"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <validation-observer
        #default="{ handleSubmit, invalid, pristine }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(submitHandle)"
        >
          <!-- SECTION form -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mb-0"
          >
            <template #header>
              <div class="w-100 d-flex-between">
                <h6 class="m-0">
                  {{ readonly ? $t('saleReport.detail.title') : $t('saleReport.detail.updateTitle') }}
                </h6>
                <div v-if="canActionSaleReportManually && getEnv !== 'production'">
                  <b-button
                    v-if="readonly"
                    variant="info"
                    class="ml-1"
                    @click="readonly = !readonly"
                  >
                    <span class="">{{ $t('edit') }}</span>
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-info"
                    class="ml-1"
                    @click="readonly = !readonly"
                  >
                    <span class="">{{ $t('cancelEdit') }}</span>
                  </b-button>
                </div>
              </div>

            </template>

            <!-- Form Input -->
            <b-card-body class="p-0 pt-1">
              <!-- SECTION Form -->

              <b-row v-if="saleReportToUpdate">
                <!-- ANCHOR Agency -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.agency')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="agency"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.agency') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <v-select
                        v-model="agencySelected"
                        :options="agenciesChildList"
                        :reduce="(val) => val"
                        :clearable="true"
                        label="agencyCode"
                        :filterable="true"
                        :disabled="readonly"
                        input-id="agency"
                        :name="$t('saleReport.createManually.agency')"
                        :placeholder="$t('saleReport.createManually.placeholder.agency')"
                        @open="openAgencySelect"
                        @input="inputAgencySelect"
                      >
                        <template #option="data">
                          <b-row>
                            <b-col
                              cols="12"
                              class="font-weight-bold text-truncate"
                            >
                              {{ data.agencyCode }}
                            </b-col>
                            <b-col
                              cols="12"
                            >
                              <small class="text-truncate">
                                {{ data.agencyName }}
                              </small>
                            </b-col>
                          </b-row>
                        </template>

                        <template #selected-option="data">
                          <div class="d-flex gap-2">
                            <div class="text-info">
                              {{ data.agencyCode }}
                            </div>

                            <div>
                              <small class="text-truncate">
                                {{ data.agencyName }}
                              </small>
                            </div>
                          </div>
                        </template>

                        <template v-slot:no-options="{ searching }">
                          <template v-if="searching">
                            {{ $t('noOptions') }}
                          </template>
                          <template v-else>
                            <b-spinner
                              variant="primary"
                              label="Text Centered"
                              small
                              tag="span"
                            />
                            <span class="pl-1">
                              {{ $t('loading') }}
                            </span>
                          </template>
                        </template>
                      </v-select>
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="agency"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Customer -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.customer')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="customer"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.customer') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <v-select
                        v-model="customerSelected"
                        :options="customerOptions"
                        :reduce="(val) => val.code"
                        clearable
                        :disabled="!agencySelected || readonly"
                        label="code"
                        :filterable="false"
                        input-id="customer"
                        :name="$t('saleReport.createManually.customer')"
                        :placeholder="$t('saleReport.createManually.placeholder.customer')"
                        @open="handleOpenCustomer"
                        @search="handleSearchCustomer"
                      >
                        <template #option="data">
                          <b-row>
                            <b-col
                              cols="12"
                              class="font-weight-bold text-truncate"
                            >
                              {{ data.code }}
                            </b-col>
                            <b-col
                              cols="12"
                            >
                              <small class="text-truncate">
                                {{ data.lastName && data.firstName ? `${data.lastName} ${data.firstName}` : (data.name ? data.name : '') }}
                              </small>
                            </b-col>
                          </b-row>
                        </template>

                        <template #selected-option="data">
                          <div class="d-flex gap-2">
                            <div class="text-info">
                              {{ data.code }}
                            </div>

                            <div>
                              <small class="text-truncate">
                                {{ data.lastName && data.firstName ? `${data.lastName} ${data.firstName}` : (data.name ? data.name : '') }}
                              </small>
                            </div>
                          </div>
                        </template>

                        <template #no-options>
                          <template v-if="isLoadingCustomerOptions">
                            <b-spinner
                              variant="primary"
                              label="Text Centered"
                              small
                              tag="span"
                            />
                            <span class="pl-1">
                              {{ $t('loading') }}
                            </span>
                          </template>
                          <template v-else>
                            {{ $t('noOptions') }}
                          </template>
                        </template>
                      </v-select>
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="customer"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Ticket number -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.ticketNumber')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="ticketNumber"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.ticketNumber') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="ticketNumber"
                        v-model="saleReportToUpdate.ticketNumber"
                        v-remove-non-numeric-chars.allNumber
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.ticketNumber')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="ticketNumber"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Airline -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.airline')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="airline"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.airline') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="airline"
                        v-model="saleReportToUpdate.airline"
                        :disabled="readonly"
                        maxlength="2"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="upperCaseFormatter"
                        :placeholder="$t('saleReport.createManually.placeholder.airline')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="airline"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Source -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.source')"
                    rules="required|max:2"
                  >
                    <b-form-group
                      label-for="source"
                      :class="getValidationState(validationContext) === false ? 'is-invalid' : ''"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.source') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <!-- <b-form-input
                          id="source"
                          v-model="saleReportToUpdate.source"
                          maxlength="2"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :formatter="upperCaseFormatter"
                          :placeholder="$t('saleReport.createManually.placeholder.source')"
                        /> -->
                      <v-select
                        id="source"
                        v-model="saleReportToUpdate.source"
                        :options="distributorExtendOptions"
                        :reduce="(val) => val.value"
                        :disabled="readonly"
                        label="label"
                        filterable
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.source')"
                      >
                        <template #option="data">
                          <div
                            class="text-truncate"
                          >
                            {{ data.label }}
                          </div>
                        </template>

                        <template #selected-option="data">
                          <div
                            class="text-truncate"
                          >
                            {{ data.label }}
                          </div>
                        </template>

                        <template v-slot:no-options="{ searching }">
                          <template v-if="searching">
                            {{ $t('noOptions') }}
                          </template>
                          <template v-else>
                            <b-spinner
                              variant="primary"
                              label="Text Centered"
                              small
                              tag="span"
                            />
                            <span class="pl-1">
                              {{ $t('loading') }}
                            </span>
                          </template>
                        </template>
                      </v-select>
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="source"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Booking code -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.bookingCode')"
                    rules="required|max:6"
                  >
                    <b-form-group
                      label-for="bookingCode"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.bookingCode') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="bookingCode"
                        v-model="saleReportToUpdate.bookingCode"
                        maxlength="6"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="upperCaseFormatter"
                        :placeholder="$t('saleReport.createManually.placeholder.bookingCode')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="bookingCode"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- SECTION pax info -->
                <!-- ANCHOR paxName -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.paxName')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="paxName"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.paxName') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="paxName"
                        v-model="saleReportToUpdate.paxName"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="removeAccentsUpperCaseFormatter"
                        lazy-formatter
                        :disabled="readonly"
                        :placeholder="$t('saleReport.createManually.placeholder.paxName')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="paxName"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR paxType -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.paxType')"
                    rules=""
                  >
                    <b-form-group
                      label-for="paxType"
                    >
                      <template
                        #label
                      >
                        {{ $t('saleReport.createManually.paxType') }}
                      </template>
                      <v-select
                        id="paxType"
                        v-model="saleReportToUpdate.paxType"
                        :options="['ADULT', 'CHILD', 'INFANT']"
                        :reduce="(val) => val"
                        :clearable="true"
                        label="label"
                        :disabled="readonly"
                        :filterable="true"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :name="$t('saleReport.createManually.paxType')"
                        :placeholder="$t('saleReport.createManually.placeholder.paxType')"
                      >
                        <template #option="data">
                          <span>{{ $t(`reservation.${data.label}`) }}</span>
                        </template>

                        <template #selected-option="data">
                          <span>{{ $t(`reservation.${data.label}`) }}</span>
                        </template>
                      </v-select>
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="paxType"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR paxId -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.paxId')"
                    rules=""
                  >
                    <b-form-group
                      label-for="paxId"
                    >
                      <template
                        #label
                      >
                        {{ $t('saleReport.createManually.paxId') }}
                      </template>
                      <b-form-input
                        id="paxId"
                        v-model="saleReportToUpdate.paxId"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :disabled="readonly"
                        :placeholder="$t('saleReport.createManually.placeholder.paxId')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="paxId"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- !SECTION -->

                <!-- ANCHOR bookingDate -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.bookingDate')"
                    rules=""
                  >
                    <b-form-group
                      label-for="bookingDate"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.bookingDate') }} (YYYY-MM-DD)
                      </template>
                      <b-form-input
                        id="bookingDate"
                        v-model="saleReportToUpdate.bookingDate"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.bookingDate')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="bookingDate"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR issueDate -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.issueDate')"
                    rules=""
                  >
                    <b-form-group
                      label-for="issueDate"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.issueDate') }} (YYYY-MM-DD)
                      </template>
                      <b-form-input
                        id="issueDate"
                        v-model="saleReportToUpdate.issueDate"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.issueDate')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="issueDate"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR trips -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.trips')"
                    rules=""
                  >
                    <b-form-group
                      label-for="trips"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.trips') }}
                      </template>
                      <b-form-input
                        id="trips"
                        v-model="saleReportToUpdate.trips"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.trips')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="trips"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR bookingClass -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.bookingClass')"
                    rules=""
                  >
                    <b-form-group
                      label-for="bookingClass"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.bookingClass') }}
                      </template>
                      <b-form-input
                        id="bookingClass"
                        v-model="saleReportToUpdate.bookingClass"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.bookingClass')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="bookingClass"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR fareBasisCode -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.fareBasisCode')"
                    rules=""
                  >
                    <b-form-group
                      label-for="fareBasisCode"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.fareBasisCode') }}
                      </template>
                      <b-form-input
                        id="fareBasisCode"
                        v-model="saleReportToUpdate.fareBasisCode"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.fareBasisCode')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="fareBasisCode"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR booker -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.booker')"
                    rules=""
                  >
                    <b-form-group
                      label-for="booker"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.booker') }}
                      </template>
                      <b-form-input
                        id="booker"
                        v-model="saleReportToUpdate.booker"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.booker')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="booker"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR issuer -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.issuer')"
                    rules=""
                  >
                    <b-form-group
                      label-for="issuer"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.issuer') }}
                      </template>
                      <b-form-input
                        id="issuer"
                        v-model="saleReportToUpdate.issuer"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.issuer')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="issuer"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR signBook -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.signBook')"
                    rules=""
                  >
                    <b-form-group
                      label-for="signBook"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.signBook') }}
                      </template>
                      <b-form-input
                        id="signBook"
                        v-model="saleReportToUpdate.signBook"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.signBook')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="signBook"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR signIss -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.signIss')"
                    rules=""
                  >
                    <b-form-group
                      label-for="signIss"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.signIss') }}
                      </template>
                      <b-form-input
                        id="signIss"
                        v-model="saleReportToUpdate.signIss"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.signIss')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="signIss"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR ticketType -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.ticketType')"
                    rules=""
                  >
                    <b-form-group
                      label-for="ticketType"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.ticketType') }}
                      </template>
                      <b-form-input
                        id="ticketType"
                        v-model="saleReportToUpdate.ticketType"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.ticketType')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="ticketType"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR flightType -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.flightType')"
                    rules=""
                  >
                    <b-form-group
                      label-for="flightType"
                    >
                      <template
                        #label
                      >
                        {{ $t('saleReport.createManually.flightType') }}
                      </template>
                      <v-select
                        id="flightType"
                        v-model="saleReportToUpdate.flightType"
                        :disabled="readonly"
                        :options="[
                          { label: 'saleReport.domestic', value: 'Domestic' },
                          { label: 'saleReport.inbound', value: 'Inbound' }
                        ]"
                        :reduce="(val) => val.value"
                        :clearable="true"
                        label="label"
                        :filterable="true"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :name="$t('saleReport.createManually.flightType')"
                        :placeholder="$t('saleReport.createManually.placeholder.flightType')"
                      >
                        <template #option="data">
                          <span>{{ $t(`${data.label}`) }}</span>
                        </template>

                        <template #selected-option="data">
                          <span>{{ $t(`${data.label}`) }}</span>
                        </template>
                      </v-select>
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="flightType"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR flownFare -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.flownFare')"
                    rules=""
                  >
                    <b-form-group
                      label-for="flownFare"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.flownFare') }}
                      </template>
                      <b-form-input
                        id="flownFare"
                        v-model="saleReportToUpdate.flownFare"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.flownFare')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="flownFare"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR flownDate -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.flownDate')"
                    rules=""
                  >
                    <b-form-group
                      label-for="flownDate"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.flownDate') }}
                      </template>
                      <b-form-input
                        id="flownDate"
                        v-model="saleReportToUpdate.flownDate"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.flownDate')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="flownDate"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR flightDates -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.flightDates')"
                    rules=""
                  >
                    <b-form-group
                      label-for="flightDates"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.flightDates') }}
                      </template>
                      <b-form-input
                        id="flightDates"
                        v-model="saleReportToUpdate.flightDates"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.flightDates')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="flightDates"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR currency -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.currency')"
                    rules=""
                  >
                    <b-form-group
                      label-for="currency"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.currency') }}
                      </template>
                      <b-form-input
                        id="currency"
                        v-model="saleReportToUpdate.currency"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.currency')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="currency"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR fFlyer -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.fFlyer')"
                    rules=""
                  >
                    <b-form-group
                      label-for="fFlyer"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.fFlyer') }}
                      </template>
                      <b-form-input
                        id="fFlyer"
                        v-model="saleReportToUpdate.fFlyer"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.fFlyer')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="fFlyer"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR priceTicket -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.priceTicket')"
                    rules="required|min_value:0"
                  >
                    <b-form-group
                      label-for="priceTicket"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.priceTicket') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        class="d-none"
                        :value="saleReportToUpdate.priceTicket"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <IAmInputMoney
                        id="priceTicket"
                        :value-money.sync="saleReportToUpdate.priceTicket"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.priceTicket')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="priceTicket"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR tax -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.tax')"
                    rules=""
                  >
                    <b-form-group
                      label-for="tax"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.tax') }}
                      </template>
                      <IAmInputMoney
                        id="tax"
                        :value-money.sync="saleReportToUpdate.tax"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.tax')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="tax"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR otherTax -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.otherTax')"
                    rules=""
                  >
                    <b-form-group
                      label-for="otherTax"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.otherTax') }}
                      </template>
                      <IAmInputMoney
                        id="otherTax"
                        :value-money.sync="saleReportToUpdate.otherTax"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.otherTax')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="otherTax"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR fee -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.fee')"
                    rules=""
                  >
                    <b-form-group
                      label-for="fee"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.fee') }}
                      </template>
                      <IAmInputMoney
                        id="fee"
                        :value-money.sync="saleReportToUpdate.fee"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.fee')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="fee"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR collectionFee -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.collectionFee')"
                    rules=""
                  >
                    <b-form-group
                      label-for="collectionFee"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.collectionFee') }}
                      </template>
                      <IAmInputMoney
                        id="collectionFee"
                        :value-money.sync="saleReportToUpdate.collectionFee"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.collectionFee')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="collectionFee"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR feeService -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.feeService')"
                    rules=""
                  >
                    <b-form-group
                      label-for="feeService"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.feeService') }}
                      </template>
                      <IAmInputMoney
                        id="feeService"
                        :value-money.sync="saleReportToUpdate.feeService"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.feeService')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="feeService"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR commissionPrepaid -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.commissionPrepaid')"
                    rules=""
                  >
                    <b-form-group
                      label-for="commissionPrepaid"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.commissionPrepaid') }}
                      </template>
                      <IAmInputMoney
                        id="commissionPrepaid"
                        :value-money.sync="saleReportToUpdate.commissionPrepaid"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.commissionPrepaid')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="commissionPrepaid"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR unpaidCommission -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.unpaidCommission')"
                    rules=""
                  >
                    <b-form-group
                      label-for="unpaidCommission"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.unpaidCommission') }}
                      </template>
                      <IAmInputMoney
                        id="unpaidCommission"
                        :value-money.sync="saleReportToUpdate.unpaidCommission"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.unpaidCommission')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="unpaidCommission"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR commission -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.commission')"
                    rules=""
                  >
                    <b-form-group
                      label-for="commission"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.commission') }}
                      </template>
                      <IAmInputMoney
                        id="commission"
                        :value-money.sync="saleReportToUpdate.commission"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('saleReport.createManually.placeholder.commission')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="commission"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR originalTk -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.originalTk')"
                    rules=""
                  >
                    <b-form-group
                      label-for="originalTk"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.originalTk') }}
                      </template>
                      <b-form-input
                        id="originalTk"
                        v-model.number="saleReportToUpdate.originalTk"
                        v-remove-non-numeric-chars.allNumber
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.originalTk')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="originalTk"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR ticketSubType -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('saleReport.createManually.ticketSubType')"
                    rules=""
                  >
                    <b-form-group
                      label-for="ticketSubType"
                    >
                      <template #label>
                        {{ $t('saleReport.createManually.ticketSubType') }}
                      </template>
                      <b-form-input
                        id="ticketSubType"
                        v-model.number="saleReportToUpdate.ticketSubType"
                        v-remove-non-numeric-chars.allNumber
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimInput"
                        :placeholder="$t('saleReport.createManually.placeholder.ticketSubType')"
                      />
                      <b-tooltip
                        v-if="validationContext.errors[0]"
                        target="ticketSubType"
                        triggers="hover focus"
                        placement="top"
                        boundary="viewport"
                        variant="danger"
                      >
                        <h6 class="text-white py-25 mb-0">
                          {{ validationContext.errors[0] }}
                        </h6>
                      </b-tooltip>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>

            <!-- !SECTION -->
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Action Buttons -->
          <div class="d-flex py-1 justify-content-center">
            <b-button
              variant="secondary"
              @click="backHandle"
            >
              {{ $t('back') }}
            </b-button>

            <b-button
              v-if="!readonly"
              variant="danger"
              class="mx-1"
              type="reset"
              :disabled="pristine"
              @click="clearHandle"
            >
              {{ $t('clear') }}
            </b-button>

            <b-button
              v-if="!readonly"
              variant="info"
              type="submit"
            >
              {{ $t('saleReport.detail.update') }}
            </b-button>
          </div>
        <!-- !SECTION -->

        </b-form>
      </validation-observer>
    </b-overlay>
  </section>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton, BRow, BCol, BCard, BSpinner, BOverlay,
  BCardBody, BTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, onUnmounted, onBeforeMount, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { debounce } from 'lodash'

import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import { distributorExtendOptions } from '@/constants/selectOptions'
import IAmInputMoney from '@/components/IAmInputMoney.vue'
import router from '@/router'
import { TYPE_CAN_CREATE_UPDATE_MANUALLY } from '@/constants/saleReport'

import formValidation from '@core/comp-functions/forms/form-validation'
import {
  trimInput, lowerCaseFormatter, upperCaseFormatter, removeAccentsUpperCaseFormatter,
} from '@core/comp-functions/forms/formatter-input'
import {
  formatCurrency,
} from '@core/utils/filter'

import useSaleReportHandle from '@saleReport/useSaleReportHandle'
import saleReportStoreModule from '@saleReport/saleReportStoreModule'

import {
  required, min, max, email, phoneNumber,
} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BOverlay,
    BTooltip,
    vSelect,
    IAmInputMoney,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const SALEREPORT_APP_STORE_MODULE_NAME = 'app-sale-report'

    // Register module
    if (!store.hasModule(SALEREPORT_APP_STORE_MODULE_NAME)) {
      store.registerModule(SALEREPORT_APP_STORE_MODULE_NAME, saleReportStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALEREPORT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SALEREPORT_APP_STORE_MODULE_NAME)
      }
    })
    const {
      getSaleReportById,
      fetchAgenciesChild,
      loading,
      getCustomersByAgency,
      updateSaleReport,
    } = useSaleReportHandle()

    const saleReportData = ref(null)
    const readonly = ref(true)

    const saleReportToUpdate = ref()
    const resetSaleReportData = () => {
      saleReportToUpdate.value = JSON.parse(JSON.stringify({
        ...saleReportData.value,
        issuer: saleReportData.value.issuer?.employeeCode,
        booker: saleReportData.value.booker?.employeeCode,
      }))
    }
    const agencySelected = ref()
    const customerSelected = ref()
    onBeforeMount(async () => {
      await getSaleReportById(router.currentRoute.params.id)
        .then(res => {
          saleReportData.value = res.data
          resetSaleReportData()
          agencySelected.value = res.data.agency
          customerSelected.value = {
            code: res.data.customer,
            name: res.data.customerName,
          }
        })
    })
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetSaleReportData)

    function submitHandle() {
      const payload = {
        ...saleReportToUpdate.value,
        agencyCode: agencySelected.value ? agencySelected.value.agencyCode : '',
        customerCode: customerSelected.value ? customerSelected.value.code : '',
      }

      refFormObserver.value.validate()
        .then(success => {
          if (success) {
            updateSaleReport(payload)
              .then(() => {
                readonly.value = true
              })
          }
        })
        .catch(err => {
          console.error({ err })
        })
    }

    function backHandle() {
      resetForm()
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    const agenciesChildList = ref([])
    function openAgencySelect() {
      if (!agenciesChildList.value.length) {
        const id = getUserData().employeeData.agency.id
        fetchAgenciesChild(id).then(res => {
          agenciesChildList.value = res.data.items
        })
      }
    }

    const customerOptions = ref([])
    const customerOptionsDefault = ref([])
    const isLoadingCustomerOptions = ref(false)
    function handleOpenCustomer() {
      isLoadingCustomerOptions.value = true
      if (!customerOptionsDefault.value?.length && agencySelected.value?.id) {
        getCustomersByAgency(agencySelected.value?.id)
          .then(res => {
            customerOptions.value = res
            customerOptionsDefault.value = res
          }).finally(() => {
            isLoadingCustomerOptions.value = false
          })
      } else {
        customerOptions.value = customerOptionsDefault.value
        isLoadingCustomerOptions.value = false
      }
    }

    const handleSearchCustomer = debounce(searchText => {
      if (searchText) {
        isLoadingCustomerOptions.value = true
        getCustomersByAgency(agencySelected.value?.id, searchText).then(res => {
          customerOptions.value = res
        })
          .finally(() => {
            isLoadingCustomerOptions.value = false
          })
      }
    }, 200)

    function inputAgencySelect() {
      customerOptions.value = []
      customerOptionsDefault.value = []
      customerSelected.value = null
    }
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const canActionSaleReportManually = computed(() => isRoleF1.value && TYPE_CAN_CREATE_UPDATE_MANUALLY.includes(store.getters['userStore/getMeDataType']))

    const getEnv = computed(() => store.getters['app/getEnv'])
    return {
      saleReportToUpdate,
      refFormObserver,
      getValidationState,

      // Validations
      required,
      min,
      max,
      email,
      phoneNumber,

      submitHandle,
      backHandle,
      clearHandle,

      trimInput,
      lowerCaseFormatter,
      upperCaseFormatter,
      removeAccentsUpperCaseFormatter,
      formatCurrency,

      openAgencySelect,
      agenciesChildList,
      customerOptions,
      loading,
      agencySelected,
      customerSelected,
      handleOpenCustomer,
      handleSearchCustomer,
      distributorExtendOptions,
      inputAgencySelect,
      isLoadingCustomerOptions,
      readonly,

      canActionSaleReportManually,
      getEnv,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#sale-report-create-manually ::v-deep{
  .form-group {
    label {
      font-size: 0.9rem;
      white-space: nowrap;
    }

    margin-bottom: 0.7rem;
  }
}
</style>
